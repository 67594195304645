import React from "react"
import { graphql, Link } from "gatsby"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import { Columns, Section } from "../components/Containers"
import Text from "../components/Text"
import { Accordion } from "../components/Accordion"

const ProceduresQueryPage = ({ pageContext, location, data }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const language = pageContext.language

  post.groups.map(g => {
    if (g.category === "endo") {
      g.services = data.endoServices.nodes
    }

    if (g.category === "oral") {
      g.services = data.oralServices.nodes
    }
  })

  const [topGroup, ...restGroups] = post.groups

  let schemaData
  if (language === "en") {
    schemaData = {
      presets: post.schemas.presets,
      customSchema: post.schemas.custom,
      dateModified: pageContext.dateModified,
      language,
      metaDescription: post.metaDescription,
      path: location.pathname,
      productontologyRelations: post.productontologyRelations
    }
  }

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          schemaData={schemaData}
          pathname={location.pathname}
          robots={post.robotsNoindex ? "noindex" : ""}
        />
        <div className="procedure-query nav-spacer">
          <Section smallSpacing>
            <Columns sideColumnsSize="3">
              <div className="column">
                <Text text={post.heading} as="h1" />
                <Text text={post.topBlurb} as="p" />
              </div>
            </Columns>
          </Section>

          <Section className="mt-0">
            <Columns sideColumnsSize="3">
              <div
                className="column"
                id={topGroup.heading.split(" ").join("-").toLowerCase()}>
                <Text text={topGroup.heading} as="h2" />
                <Text text={topGroup.blurb} as="p" />
                <div
                  className={`procedure-query__services ${post.heading
                    .split(" ")
                    .join("-")
                    .toLowerCase()}`}>
                  {topGroup.services.map((s, i) => (
                    <ServiceCard
                      key={i}
                      href={s.title}
                      image={s.query.queryImage ? s.query.queryImage : null}
                      heading={s.query.heading}
                      blurb={s.query.blurb}
                    />
                  ))}
                </div>
              </div>
            </Columns>
          </Section>

          <Section smallSpacing>
            <Columns sideColumnsSize="3">
              <div className="column">
                {restGroups.map((group, i) => (
                  <React.Fragment key={i}>
                    <Text text={group.heading} as="h2" />
                    <Text text={group.blurb} as="p" />
                    <div className="procedure-query__services">
                      {group.services.map((s, i) => (
                        <ServiceCard
                          key={i}
                          href={s.title}
                          image={s.query.queryImage ? s.query.queryImage : null}
                          heading={s.query.heading}
                          blurb={s.query.blurb}
                        />
                      ))}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </Columns>
          </Section>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

const ServiceCard = ({ image, heading, blurb, href }) => {
  return (
    <div className="procedure-query__card">
      {image && (
        <Link to={`/${href}`} title={`Learn More About ${heading}`}>
          <ImageMeta publicId={image} cloudname="nuvolum" width="auto" />
        </Link>
      )}
      <div>
        <Link to={`/${href}`} title={`Learn More About ${heading}`}>
          {heading != "Root Canal Treatment" ? (
            <Text text={heading} as="h3" className={!image ? "mt-0" : null} />
          ) : (
            <Text
              text={heading}
              as="h2"
              style={{
                marginTop: 20,
                marginBottom: "0.6666rem",
                color: "##0050bb"
              }}
              className={!image ? "mt-0" : null}
            />
          )}
        </Link>
        <Text text={blurb} as="p" />
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query proceduresQueryPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        metaTitle
        metaDescription
        robotsNoindex
        heading
        topBlurb
        groups {
          category
          heading
          blurb
        }
        productontologyRelations
        schemas {
          presets
          custom
        }
      }
    }
    endoServices: allProceduresJson(
      filter: { category: { eq: "endo" }, query: { isHidden: { ne: true } } }
      sort: { fields: order }
    ) {
      nodes {
        category
        title
        query {
          blurb
          heading
          queryImage
        }
      }
    }
    oralServices: allProceduresJson(
      filter: { category: { eq: "oral" }, query: { isHidden: { ne: true } } }
      sort: { fields: order }
    ) {
      nodes {
        category
        title
        query {
          blurb
          heading
          queryImage
        }
      }
    }
  }
`

export default ProceduresQueryPage
